// HomePage.js
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import './HomePage.css';
import PennySection from './PennySection';

// Import existing components
import cardData from './cardData.json';
import FAQ from './FAQ';
import HowItWorks from './HowItWorks';
import HomeCarousel from './HomeCarousel';
import BitcoinLockSection from './BitcoinLockSection';

function HomePage({ userState, setUserState }) {
  const navigate = useNavigate();
  const packagesRef = useRef(null);

  const navigateToSignupWizard = () => {
    navigate('/signup');
  };

  return (
    <>
      <HomeCarousel navigateToSignupWizard={navigateToSignupWizard} />

      <div className="marketing-and-registry-section">
        <Container>
          <div className="marketing-content">
            <h1 className='marketing-title'>
              Build Your Child's Future with Help from Family & Friends
            </h1>
            <p className="marketing-text">
              Replace throwaway gifts with lasting wealth. Create a registry where loved ones can contribute to your child's future, guided by Penny, your AI assistant.
            </p>
          </div>

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4" ref={packagesRef}>
            {cardData.map((card, index) => (
              <div key={index} className="col">
                <Card className="registry-card">
                  <Card.Img variant="top" src={card.image} />
                  <Card.Body>
                    <Card.Title className="card-title">{card.title}</Card.Title>
                    <Card.Text className="card-text">{card.problem}</Card.Text>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>

          <div className="cta-container">
            <button className="cta-button" onClick={() => navigate('/signup')}>
              Create a Registry
            </button>
          </div>
        </Container>
      </div>

      <HowItWorks />
      {/* <div className="howitworks-container">
        <Container>
          <div className="cta-container">
            <button className="cta-button" onClick={() => navigate('/signup')}>
              Start Saving Today
            </button>
          </div>
        </Container>
      </div> */}

      <PennySection />

      <BitcoinLockSection />
      <div className="bitcoin-lock-section">
        <Container>
          <div className="cta-container">
            <button className="cta-button" onClick={() => navigate('/signup')}>
              Secure Their Future
            </button>
          </div>
        </Container>
      </div>

      <FAQ />
      <div className="faq-section">
        <Container>
          <div className="cta-container">
            <button className="cta-button" onClick={() => navigate('/signup')}>
              Get Started Now
            </button>
          </div>
        </Container>
      </div>
    </>
  );
}

export default HomePage;