// src/components/FloatingAssistant.js
import React, { useState, createContext, useContext, useEffect } from 'react';
import { LiveKitRoom, useVoiceAssistant, BarVisualizer, RoomAudioRenderer } from '@livekit/components-react';
import '@livekit/components-styles';
import { ChevronDown, ChevronUp, X, Mic } from 'lucide-react';
import './VoiceAssistant.css';

// Create context for voice assistant state
const VoiceAssistantContext = createContext();

// Provider component
export function VoiceAssistantProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const value = {
    isOpen,
    openAssistant: () => setIsOpen(true),
    closeAssistant: () => setIsOpen(false)
  };

  return (
    <VoiceAssistantContext.Provider value={value}>
      {children}
    </VoiceAssistantContext.Provider>
  );
}

// Hook for accessing voice assistant state
export function useVoiceAssistantContext() {
  const context = useContext(VoiceAssistantContext);
  if (!context) {
    throw new Error('useVoiceAssistantContext must be used within a VoiceAssistantContext.Provider');
  }
  return context;
}

// Button component that uses the main CTA style
export function VoiceButton({ className = "cta-button" }) {
  const { openAssistant } = useVoiceAssistantContext();
  return (
    <button
      onClick={openAssistant}
      className={className}
      aria-label="Talk to AI Assistant"
    >
      Talk to Penny
    </button>
  );
}

function VoiceAssistantUI() {
  const { state, audioTrack } = useVoiceAssistant();
  const [isExpanded, setIsExpanded] = useState(false);
  const { closeAssistant } = useVoiceAssistantContext();
  const [timer, setTimer] = useState(0);

  // Timer logic
  useEffect(() => {
    let interval;
    if (state === 'listening' || state === 'speaking') {
      interval = setInterval(() => {
        setTimer(prev => prev + 1);
      }, 1000);
    }
    
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [state]);

  // Format timer to MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className={`voice-assistant-content ${isExpanded ? 'expanded' : ''}`} data-state={state}>
      {isExpanded ? (
        <div className="voice-assistant-expanded">
          <div className="expanded-header">
            <h3>Gift Registry Assistant</h3>
            <div className="header-controls">
              <button 
                className="control-button" 
                onClick={() => setIsExpanded(false)}
              >
                <ChevronDown />
              </button>
              <button 
                className="control-button"
                onClick={closeAssistant}
              >
                <X />
              </button>
            </div>
          </div>
          <div className="visualization-container">
            <BarVisualizer 
              state={state}
              trackRef={audioTrack}
              barCount={5}
              className="audio-visualizer"
            />
          </div>
          <div className="expanded-footer">
            <div className="state-indicator">
              {state === 'listening' && (
                <>
                  <span className="state-icon">⚪️</span>
                  listening...
                </>
              )}
              {state === 'speaking' && (
                <>
                  <span className="state-icon">🔵</span>
                  speaking...
                </>
              )}
              {state === 'thinking' && (
                <>
                  <span className="state-icon">💭</span>
                  thinking...
                </>
              )}
            </div>
            <div className="timer">{formatTime(timer)}</div>
          </div>
          <div className="powered-by">Powered by TrustFund</div>
        </div>
      ) : (
        <div className="voice-assistant-compact">
          <div className="compact-content">
            <span className="assistant-title">Gift Registry Assistant</span>
            <span className="timer">{formatTime(timer)}</span>
          </div>
          <div className="assistant-controls">
            <button 
              className="control-button expand" 
              onClick={() => setIsExpanded(true)}
            >
              <ChevronUp />
            </button>
            <button 
              className="control-button close"
              onClick={closeAssistant}
            >
              <X />
            </button>
          </div>
        </div>
      )}
      <RoomAudioRenderer />
    </div>
  );
}

export function FloatingAssistant({ token, serverUrl, showFloatingButton = true }) {
  const { isOpen, openAssistant } = useVoiceAssistantContext();

  return (
    <>
      {/* Floating microphone button */}
      {showFloatingButton && (
        <button
          onClick={openAssistant}
          className="voice-assistant-button"
          aria-label="Open Voice Assistant"
        >
          <Mic size={24} />
        </button>
      )}

      {/* Voice Assistant Modal */}
      {isOpen && (
        <LiveKitRoom
          token={token}
          serverUrl={serverUrl}
          connect={true}
          audio={true}
        >
          <VoiceAssistantUI />
        </LiveKitRoom>
      )}
    </>
  );
}