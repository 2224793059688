import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import { FaGift, FaShieldAlt, FaSeedling } from 'react-icons/fa';
import './RegistryPage.css';

const RegistryPage = () => {
  // eslint-disable-next-line no-unused-vars
  const { registryId } = useParams();
  const location = useLocation();
  const presetAmounts = [25, 50, 100, 200, 300, 500];
  
  const registryData = location.state?.registryData || {
    title: "Sarah's first birthday!",
    description: "As Sarah's first birthday approaches, we are filled with joy and excitement to celebrate this special milestone in our family. Birthdays are moments of joy, love, and reflection, and we want to make Sarah's first birthday a cherished memory for all.",
    photo: null,
  };

  const getPhotoSrc = (photo) => {
    if (!photo) return null;
    if (photo instanceof File) {
      return URL.createObjectURL(photo);
    }
    return photo; // If it's already a URL string
  };

  useEffect(() => {
    return () => {
      // Cleanup object URLs when component unmounts
      if (registryData.photo instanceof File) {
        URL.revokeObjectURL(getPhotoSrc(registryData.photo));
      }
    };
  }, [registryData.photo]);

  return (
    <div className="registry-page">
      <Container className="registry-content">
        <Row>
          <Col md={8}>
            <div className="registry-photo">
              {registryData.photo ? (
                <img 
                  src={getPhotoSrc(registryData.photo)} 
                  alt={registryData.title}
                />
              ) : (
                <div className="placeholder-image">
                  <FaGift size={48} />
                  <p>No image uploaded</p>
                </div>
              )}
            </div>
            <h1 className="registry-title">{registryData.title}</h1>
            <p className="registry-description">{registryData.description}</p>
            <Button className="invite-friends-btn">Invite friends</Button>
          </Col>
          
          <Col md={4}>
            <div className="gift-form">
              <div className="gift-value">
                <label>Gift value</label>
                <div className="amount-input">
                  <span className="currency">$</span>
                  <input type="number" defaultValue="100.00" />
                  <span className="bitcoin">0.00065 ₿</span>
                </div>
                <div className="preset-amounts">
                  {presetAmounts.map(amount => (
                    <button key={amount}>${amount}</button>
                  ))}
                </div>
              </div>
              <Button className="send-bitcoin-btn">Send Bitcoin</Button>
              
              <div className="gift-tracker">
                <h3>56 people sent gifts</h3>
                <div className="gift-list">
                  <div className="gift-item">
                    <span>Annette Black</span>
                    <span>🚀</span>
                  </div>
                  <div className="gift-item">
                    <span>Anonymous</span>
                    <span>🔥</span>
                  </div>
                  <div className="gift-item">
                    <span>Jacob Jones</span>
                    <span>🎉</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="promotional-section">
          <h2>Your simple, trusted home for giving</h2>
          <p>Send gifts to your loved ones that will grow with them and make their future a better one.</p>
          
          <div className="features">
            <div className="feature">
              <div className="feature-icon">
                <FaGift size={32} />
              </div>
              <h3>Simple</h3>
              <p>Send a gift to someone you love in seconds</p>
            </div>
            <div className="feature">
              <div className="feature-icon">
                <FaShieldAlt size={32} />
              </div>
              <h3>Secure</h3>
              <p>Your gift is protected by the TrustFundBaby Guarantee</p>
            </div>
            <div className="feature">
              <div className="feature-icon">
                <FaSeedling size={32} />
              </div>
              <h3>Smart</h3>
              <p>Your gift will grow even more meaningful over time</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RegistryPage;
