// PreviewRegistryModal.js
import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import './PreviewRegistryModal.css';

const PreviewRegistryModal = ({ show, onHide, registryData, onCreateRegistry }) => {
  const presetAmounts = [25, 50, 100, 200, 300, 500];

  return (
    <Modal show={show} onHide={onHide} size="lg" centered className="registry-preview-modal">
      <Modal.Header closeButton>
        <div className="modal-logo">
          <span className="brand-text">
            <span className="brand-first">TrustFund</span>
            <span className="brand-second">Baby</span>
          </span>
          <Button 
            className="create-registry-btn"
            onClick={onCreateRegistry}
          >
            Create your registry
          </Button>
        </div>
      </Modal.Header>
      
      <Modal.Body>
        <Row>
          <Col md={8}>
            {registryData.photo && (
              <div className="registry-photo">
                <img src={URL.createObjectURL(registryData.photo)} alt="Registry" />
              </div>
            )}
            <h1 className="registry-title">{registryData.registryName}</h1>
            <p className="registry-description">{registryData.description}</p>
          </Col>
          
          <Col md={4}>
            <div className="gift-form">
              <div className="gift-value">
                <label>Gift value</label>
                <div className="amount-input">
                  <span className="currency">$</span>
                  <input type="number" defaultValue="100.00" />
                  <span className="bitcoin">0.00065 ₿</span>
                </div>
                <div className="preset-amounts">
                  {presetAmounts.map(amount => (
                    <button key={amount}>${amount}</button>
                  ))}
                </div>
              </div>
              <Button className="send-bitcoin-btn">Send Bitcoin</Button>
              
              <div className="gift-tracker">
                <h3>56 people sent gifts</h3>
                <div className="gift-list">
                  <div className="gift-item">
                    <span>Annette Black</span>
                    <span>🚀</span>
                  </div>
                  <div className="gift-item">
                    <span>Anonymous</span>
                    <span>🔥</span>
                  </div>
                  {/* Add more gift items */}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="promotional-section">
          <h2>Your simple, trusted home for giving</h2>
          <p>Send gifts to your loved ones that will grow with them and make their future a better one.</p>
          
          <div className="features">
            <div className="feature">
              <img src="/simple-icon.png" alt="Simple" />
              <h3>Simple</h3>
              <p>Send a gift to someone you love in seconds</p>
            </div>
            <div className="feature">
              <img src="/secure-icon.png" alt="Secure" />
              <h3>Secure</h3>
              <p>Your gift is protected by the TrustFundBaby Guarantee</p>
            </div>
            <div className="feature">
              <img src="/smart-icon.png" alt="Smart" />
              <h3>Smart</h3>
              <p>Your gift will grow even more meaningful over time</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreviewRegistryModal;
