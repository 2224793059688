import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import './SignupWizard.css';

function SignupWizard() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    registryName: '',
    occasion: 'Birthday',
    description: '',
    photo: null
  });

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepOne 
          formData={formData} 
          setFormData={setFormData} 
          nextStep={nextStep} 
        />;
      case 2:
        return <StepTwo 
          formData={formData} 
          setFormData={setFormData} 
          nextStep={nextStep} 
          prevStep={prevStep} 
        />;
      case 3:
        return <StepThree 
          formData={formData} 
          setFormData={setFormData} 
          prevStep={prevStep} 
        />;
      default:
        return <StepOne 
          formData={formData} 
          setFormData={setFormData} 
          nextStep={nextStep} 
        />;
    }
  };

  return (
    <div className="signup-wizard">
      <Container>
        {renderStep()}
      </Container>
    </div>
  );
}

export default SignupWizard;
