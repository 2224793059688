import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './HomeCarousel.css';
import { VoiceButton } from './FloatingAssistant';

function HomeCarousel({ navigateToSignupWizard }) {
  const carouselItems = [
    {
      image: 'baby6.webp',
      title: "Build Lasting Wealth for Your Child",
      subtitle: "Turn gifts into legacy",
      alt: "Baby with savings concept"
    },
    {
      image: 'baby9.webp',
      title: "Let Family & Friends Build Their Future",
      subtitle: "Replace throwaway gifts with Bitcoin",
      alt: "Family helping child grow"
    },
    {
      image: 'baby7.webp',
      title: "Smart Savings Assistant Included",
      subtitle: "Let Penny help manage everything",
      alt: "AI assistant helping with savings"
    }
  ];

  return (
    <Carousel 
      className="full-width-carousel" 
      controls={false}
      interval={3000}
    >
      {carouselItems.map((item, index) => (
        <Carousel.Item key={index}>
          <div className="carousel-image-container">
            <img
              className="d-block carousel-image"
              src={item.image}
              alt={item.alt}
            />
          </div>
          <Carousel.Caption>
            <div className="carousel-text-container">
              <h1 className="carousel-title">{item.title}</h1>
              <p className="carousel-subtitle">{item.subtitle}</p>
            </div>
            <VoiceButton onClick={navigateToSignupWizard} />
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default HomeCarousel;