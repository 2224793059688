// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.css';

function Footer() {
  return (
    <footer className="modern-footer">
      <Container>
        <Row className="align-items-center">
          <Col md={4}>
            <div className="footer-brand">
              <span className="brand-text">
                <span className="brand-first">TrustFund</span>
                <span className="brand-second">Baby</span>
              </span>
            </div>
          </Col>
          <Col md={4} className="text-center">
            <div className="footer-links">
              <Link to="/terms-and-conditions">Terms</Link>
              <span className="separator">·</span>
              <Link to="/privacy-policy">Privacy</Link>
              <span className="separator">·</span>
              <Link to="/cookie-policy">Cookies</Link>
            </div>
          </Col>
          <Col md={4} className="text-end">
            <div className="copyright">
              © {new Date().getFullYear()} Coinsave Inc.
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
