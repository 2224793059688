import React, { useState } from 'react';
import { Form, Row, Col, Button, ProgressBar } from 'react-bootstrap';

function StepTwo({ formData, setFormData, nextStep, prevStep }) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      nextStep();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <>
      <Row className="step-indicator">
        <Col>
          <div className="step-number">Step 2 of 3</div>
          <ProgressBar now={66} className="step-progress" />
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="my-4">Registry Details</h2>
        </Col>
      </Row>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Registry Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="registryName"
            value={formData.registryName}
            onChange={handleChange}
            placeholder="e.g., Sarah's Baby Shower"
          />
          <Form.Control.Feedback type="invalid">
            Please provide a registry name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Occasion</Form.Label>
          <Form.Select
            name="occasion"
            value={formData.occasion}
            onChange={handleChange}
            required
          >
            <option value="Baby Shower">Baby Shower</option>
            <option value="Birthday">Birthday</option>
            <option value="Wedding">Wedding</option>
            <option value="Other">Other</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Tell your guests about the occasion..."
          />
        </Form.Group>

        <div className="d-flex justify-content-between">
          <Button variant="outline-secondary" onClick={prevStep}>
            Back
          </Button>
          <Button type="submit">
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
}

export default StepTwo;
