// FAQ.js
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import faqData from './faq.json';
import './FAQ.css';

function FAQ() {
  return (
    <div className="faq-section">
      <div className="tech-grid"></div>
      <Container>
        <Row>
          <Col>
            <h2 className="faq-title">Frequently Asked Questions</h2>
            <div className="faq-container">
              <Accordion>
                {faqData.map((item, index) => (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FAQ;
