import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaGift, FaGlobe, FaPiggyBank } from 'react-icons/fa';
import './GiftBitcoin.css';

const BFBPage = () => {
  return (
    <div className="bfb-page">
      <Container>
        <Row className="hero-section">
          <Col md={6} className="hero-content">
            <h1>Give the Gift that Grows</h1>
            <p className="hero-text">
              Start their financial journey early with Bitcoin - 
              a meaningful gift that can grow with them over time. Unlike traditional presents, 
              this digital asset has the potential to appreciate in value, creating a lasting 
              impact on their future.
            </p>
            <p className="hero-text">
              Perfect for birthdays, holidays, or any special occasion. 
            </p>
            <Button className="cta-button">Send Bitcoin Now</Button>
          </Col>
          <Col md={6} className="hero-image">
            <img 
              src="/bfb.webp" 
              alt="Holiday Gift" 
              className="gift-img"
            />
          </Col>
        </Row>

        <Row className="stocking-stuffer-section">
          <Col md={6} className="stocking-image">
            <img 
              src="/bss.webp" 
              alt="Bitcoin Stocking Stuffer" 
              className="gift-img"
            />
          </Col>
          <Col md={6} className="stocking-content">
            <h2>Give Bitcoin This Holiday Season</h2>
            <p className="hero-text">
              Make this Christmas unforgettable with a Bitcoin stocking stuffer. 
              Instead of traditional toys that will be forgotten, give them a gift 
              that could grow into something remarkable. It's the perfect way to 
              introduce the next generation to the future of money.
            </p>
            <Button className="cta-button">Stuff Their Stocking with Wealth</Button>
          </Col>
        </Row>

        <section className="why-section">
          <h2>Why Gift Bitcoin?</h2>
          
          <Row className="features-grid">
            <Col md={4}>
              <div className="feature-card">
                <div className="feature-icon">
                  <FaPiggyBank />
                </div>
                <h3>Potential for Growth</h3>
                <p>Unlike traditional gifts that depreciate, Bitcoin has historically increased in value over long time periods, potentially growing into a meaningful sum for their future.</p>
              </div>
            </Col>
            
            <Col md={4}>
              <div className="feature-card">
                <div className="feature-icon">
                  <FaGift />
                </div>
                <h3>A Gift That Lasts</h3>
                <p>Instead of toys that break or clothes they'll outgrow, give them their first piece of digital property - an asset they can hold for years to come.</p>
              </div>
            </Col>
            
            <Col md={4}>
              <div className="feature-card">
                <div className="feature-icon">
                  <FaGlobe />
                </div>
                <h3>Financial Education</h3>
                <p>Start their journey into understanding money, savings, and digital assets early. It's a gift that comes with valuable lessons about the future of finance.</p>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default BFBPage;
