import React, { useState } from 'react';
import { Form, Row, Col, Button, ProgressBar } from 'react-bootstrap';
import PreviewRegistryModal from '../PreviewRegistryModal';
import { useNavigate } from 'react-router-dom';

function StepThree({ formData, setFormData, prevStep, nextStep }) {
  const [validated, setValidated] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    }
  };

  const handleFileChange = (event) => {
    setFormData(prev => ({
      ...prev,
      photo: event.target.files[0]
    }));
  };

  const handleCreateRegistry = () => {
    // Here you would make an API call to create the registry
    const registryId = 'demo-registry-1'; // This would come from your API
    navigate(`/registry/${registryId}`, { 
      state: { registryData: formData } 
    });
  };

  return (
    <>
      <Row className="step-indicator">
        <Col>
          <div className="step-number">Step 3 of 3</div>
          <ProgressBar now={100} className="step-progress" />
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="my-4">Add a Photo</h2>
        </Col>
      </Row>

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-4">
          <Form.Label>Upload a photo (optional)</Form.Label>
          <Form.Control
            type="file"
            name="photo"
            onChange={handleFileChange}
            accept="image/*"
          />
        </Form.Group>

        <div className="preview-actions mb-4">
          {formData.photo && (
            <div className="photo-preview">
              <img 
                src={URL.createObjectURL(formData.photo)} 
                alt="Preview" 
                className="preview-image"
              />
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between">
          <Button 
            variant="outline-secondary" 
            onClick={prevStep}
            className="wizard-btn-secondary"
          >
            Back
          </Button>
          <div className="d-flex gap-3">
            <Button 
              variant="outline-primary"
              onClick={() => setShowPreview(true)}
              className="wizard-btn-outline"
            >
              Preview Registry
            </Button>
            <Button 
              variant="primary"
              onClick={handleCreateRegistry}
              className="wizard-btn-primary"
            >
              Finalize Registry
            </Button>
          </div>
        </div>
      </Form>

      <PreviewRegistryModal
        show={showPreview}
        onHide={() => setShowPreview(false)}
        registryData={formData}
        onCreateRegistry={handleCreateRegistry}
      />
    </>
  );
}

export default StepThree;
