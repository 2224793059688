import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import howitworksData from './HowItWorks.json';
import './HowItWorks.css';

function HowItWorks() {
  return (
    <div className="howitworks-container" id="how-it-works">
      <Container>
        <h2 className="howitworks-title">How it Works</h2>
        <div className="howitworks-steps">
          {howitworksData.map((item, index) => (
            <React.Fragment key={item.label}>
              {index % 2 === 0 && (
                <div className="howitworks-step">
                  <Row className="align-items-center">
                    <Col md={6} className="howitworks-image-col">
                      <img 
                        src={`/${item.image}`} 
                        alt={item.label} 
                        className="howitworks-image"
                      />
                    </Col>
                    <Col md={6} className="howitworks-content-col">
                      <div className="step-content">
                        <span className="step-label">Step {index/2 + 1}</span>
                        <h3>{howitworksData[index + 1].title}</h3>
                        <p>{howitworksData[index + 1].explanation}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default HowItWorks;
