import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function PennySection() {
  const features = [
    {
      icon: "✨",
      title: "Personalized Registry Setup",
      description: "Creates the perfect registry for every occasion, customized to your family's needs"
    },
    {
      icon: "📅",
      title: "Smart Reminders",
      description: "Sends timely gift reminders to family and friends for birthdays and special occasions"
    },
    {
      icon: "📊",
      title: "Growth Updates",
      description: "Keeps you informed about contributions and helps you track your child's growing savings"
    },
    {
      icon: "💝",
      title: "Thank You Notes",
      description: "Helps you craft perfect thank you messages for contributors"
    }
  ];

  return (
    <div className="penny-section">
      <Container>
        <div className="penny-header text-center">
          <h2 className="penny-title"><br/> <br/>Meet Penny, Your Voice Enabled AI Savings Assistant</h2>
        </div>

        <Row className="penny-features">
          {features.map((feature, index) => (
            <Col md={6} lg={3} key={index} className="feature-col">
              <div className="feature-card">
                <div className="feature-icon">{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default PennySection;