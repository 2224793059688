import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './BitcoinLockSection.css';

const BitcoinLockSection = () => {
  return (
    <div className="bitcoin-lock-section">
      <div className="decorative-line"></div>
      <div className="tech-grid"></div>
      
      <Container>
        <div className="section-header">
          <h2>Secure Long-Term Savings with Bitcoin L2</h2>
          <p className="section-subtitle">Lock Bitcoin for 5-20 years using Layer 2 technology</p>
        </div>
        
        <Row className="features-grid">
          <Col md={4} className="feature-item">
            <div className="feature-content">
              <div className="feature-icon">🔒</div>
              <h3>Customizable Lockup Period</h3>
              <p>Choose a lock-up period between 5 to 20 years, ensuring funds remain untouched until maturity.</p>
            </div>
          </Col>
          
          <Col md={4} className="feature-item">
            <div className="feature-content">
              <div className="feature-icon">⚡</div>
              <h3>L2 Smart Contracts</h3>
              <p>Utilizing Bitcoin's Layer 2 protocols for secure, efficient, and immutable time-locked savings.</p>
            </div>
          </Col>
          
          <Col md={4} className="feature-item">
            <div className="feature-content">
              <div className="feature-icon">💎</div>
              <h3>Undisturbed Growth</h3>
              <p>Coins remain completely untouched during the lock period, maximizing long-term holding potential.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BitcoinLockSection;
